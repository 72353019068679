@import 'Invoice';

.lineItem {

  display: grid;
  // grid-template-columns: 1fr repeat(2, 5fr) 2fr repeat(2, 3fr) 2fr;
  // grid-template-columns: 4fr 5fr 3fr 2fr 3fr 2fr 1fr;
  grid-template-columns: 8fr 6fr 3fr 1fr;
  font-size: 13px;

  & > div {
    @extend %flex-center;
    padding: ($rhythm/4);
    border-left: 1px solid $tableBorderColor;
    width: 100%;
    line-height: $rhythm;
    min-width: $rhythm;
    &:first-of-type {
      padding: ($rhythm/2) 0;
      border-left: none;
    }
    &:last-of-type {
      padding: ($rhythm/2) 0;
    }
    &:first-of-type, &:nth-child(4), &:last-of-type {
      text-align: center;
    }
  }

}

.deleteItem {
  @extend %flex-center;
  color: $removeColor;
  background: none;
  border: 0;
  padding: 0;
  cursor: pointer;
}

input, input[type=number] {
  padding: $rhythm / 4;
  border: 1px dotted lighten($tableBorderColor, 2%);
  border-radius: $borderRadius;
  min-width: $rhythm * 2;
  width: 100%;
}
input[type=number] {
  text-align: center;
  -moz-appearance: textfield; // Hide spinner
}

/* Hide spinner */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// .li_name {
//   grid-area: n;
// }
.li_desc {
  grid-area: d;
  grid-column: span 2;
}
.liGlacctv {
  grid-area: g;
  display: none;
}
.li_qty {
  grid-area: q;
}
.li_price {
  grid-area: p;
}
.li_total {
  grid-area: t;
}
.li_del {
  grid-area: x;
}

.lineItemMobile {

  display: grid;
  // grid-template-columns: 1fr repeat(2, 5fr) 2fr repeat(2, 3fr) 2fr;
  grid-template-columns: 1fr; 
  grid-template-rows: auto;
  // grid-template-areas: "n n" "d d" "g q" "p t" "x x";
  font-size: 13px;

  & > div {
    @extend %flex-center;
    padding: ($rhythm/4);
    border-left: 1px solid $tableBorderColor;
    width: 100%;
    line-height: $rhythm;
    min-width: $rhythm;
    &:first-of-type {
      padding: ($rhythm/2) 0;
      border-left: none;
    }
    &:last-of-type {
      padding: ($rhythm/2) 0;
    }
    &:first-of-type, &:nth-child(4), &:last-of-type {
      text-align: center;
    }
    & > .liGlacct {
      display: none;
    }
  }
  
  & > div.li_name {grid-area: n;
    grid-column: span 2;}

}

.lineItemMgr {

  display: grid;
  grid-template-columns: 7fr 6fr 2fr 3fr;
  font-size: 13px;

  & > div {
    @extend %flex-center;
    padding: ($rhythm/4);
    border-left: 1px solid $tableBorderColor;
    width: 100%;
    line-height: $rhythm;
    min-width: $rhythm;
    &:first-of-type {
      padding: ($rhythm/2) 0;
      border-left: none;
    }
    &:last-of-type {
      padding: ($rhythm/2) 0;
    }
    &:first-of-type, &:nth-child(4), &:last-of-type {
      text-align: center;
    }
  }

}
